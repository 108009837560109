/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { navigate } from '@reach/router'
import { MDXProvider } from '@mdx-js/react'

import ContentRightSidebar from '../../components/Layout/ContentRightSidebar/ContentRightSidebar'
import headerProps from '../../components/propTypes/headerProps'
import {
  Divider,
  MdxWrapper,
  Typography,
  PressOfficeWidget,
  TemplateBreadCrumbs,
} from '../../components'
import { format, isPast } from 'date-fns'
import { path } from 'ramda'
import Img from '../../components/gatsbyWrappers/Img'
import styles from './EventPageTemplate.module.css'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { useEffectOnce } from 'react-use'

export const EventPageTemplate = ({ data }) => {
  const { page } = data
  const { frontmatter } = page

  const eventStartDate = path(['startDate'], frontmatter)
  const isPastEvent = isPast(new Date(eventStartDate))
  const eventTypeTitle = isPastEvent ? 'Past Events' : 'Upcoming Events'

  const eventMonth = format(new Date(eventStartDate), 'MMMM')

  const {
    title,
    featuredImage,
    startDate,
    archive,
    city = '',
    venue = '',
    editor,
  } = frontmatter

  useEffectOnce(() => {
    // redirect to homepage if event is archived
    if (archive) {
      navigate('/convening')
    }
  })

  const breadcrumbs = [
    { label: 'Events' },
    {
      label: eventTypeTitle,
      url: isPastEvent ? '/convening/past-events' : '/convening',
    },
  ]

  return (
    <MDXProvider>
      <MdxWrapper>
        <TemplateBreadCrumbs breadcrumbs={breadcrumbs} />
        <Divider thickness={4} />
        <Typography type="boxHeading">{eventTypeTitle}</Typography>
        <Typography type="h3" className={styles.monthTitle}>
          {eventMonth}
        </Typography>
        {featuredImage && <Img src={featuredImage} alt={title} />}
        <Typography tag="h1" type="h3">
          {title}
        </Typography>
        <Typography type="h4" className={styles.meta}>
          <strong>Date:</strong> {format(new Date(startDate), 'd MMMM yyyy')} |
          &nbsp;
          <strong>City:</strong> {city} | &nbsp;
          <strong>Venue:</strong> {venue}
        </Typography>
        <MDXRenderer>{editor}</MDXRenderer>
      </MdxWrapper>
    </MDXProvider>
  )
}

EventPageTemplate.propTypes = {}

const EventPage = ({ data }) => {
  const {
    page: { frontmatter },
  } = data

  const { header } = frontmatter

  return (
    <ContentRightSidebar header={header}>
      <EventPageTemplate data={data} />
      <div>
        <PressOfficeWidget />
      </div>
    </ContentRightSidebar>
  )
}

EventPage.propTypes = {
  page: PropTypes.shape({
    frontmatter: PropTypes.shape({
      header: headerProps,
    }),
  }),
}

export default EventPage

export const pageQuery = graphql`
  query EventPageTemplate($slug: String!) {
    page: mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        ...headerFields
        title
        startDate
        archive
        city
        venue
        featuredImage {
          childCloudinaryMediaImage {
            fixed(width: 600) {
              aspectRatio
              height
              src
              srcSet
              width
            }
          }
        }
        editor
      }
    }
  }
`
